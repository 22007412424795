import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import { LoginForm } from "../forms";
import {
  Header,
  RightBar,
  LeftBar,
  MainContent
} from "../../components/layouts";
import { getUrlParam, redirectUnsupportedBrowsers } from "../../utils";

const styles = theme => ({
  ...theme.style.basePageLayout,
  [theme.breakpoints.down("sm")]: {
    ...theme.style.mobileBasePageLayout
  }
});

class LoginPage extends Component {
  constructor(props) {
    super(props);

    redirectUnsupportedBrowsers();
  }

  componentDidMount() {}

  adjustUrl = userId => {
    const { session, history } = this.props;
    if (userId !== undefined) {
      if (!session.vetted) {
        history.push("/waiting?vi=" + userId);
      } else {
        history.push("/main?vi=" + userId);
      }
    }
  };

  getLoginForm = () => {
    return (
      <LoginForm
        onSuccess={userId => this.adjustUrl(userId)}
        passcode={getUrlParam("passcode")}
        username={getUrlParam("username")}
        userDefined={getUrlParam("udf")}
        userDefined2={getUrlParam("udf2")}
        userDefined3={getUrlParam("udf3")}
        userDefined4={getUrlParam("udf4")}
        agent={this.props.agent}
        recorder={this.props.recorder}
      />
    );
  };

  render() {
    if (this.props.session.userId != null) {
      console.log("LoginPage userId: ", this.props.session.userId);
    }

    const { classes } = this.props;
    const component = this.getLoginForm();

    return (
      <Fragment>
        <CssBaseline />
        <Header />
        <div className={classes.root} id="mainContent">
          <div className={classes.headerSpacer} />
          <LeftBar />
          <MainContent component={component} />
          <RightBar />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ session }) => ({
  session
});

export default withStyles(styles)(
  withRouter(connect(mapStateToProps)(LoginPage))
);
