import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { IconButton, Badge } from "@material-ui/core";
import LocalizedText from "../reusable/LocalizedText";
import CTXBasePopper from "../reusable/CTXBasePopper";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import RecordIcon from "../Icons/RecordIcon";
import { getTextColor } from "../../style";
import { injectIntl } from "react-intl";
import { getIntl, TitleNewLine, isHost } from "../../utils";
import {
  handleConferenceApiRequest,
  setMorePopperExpansion
} from "../../actions";
import { showInviteParticipant } from "../invite";
import QAIcon from "../Icons/QAIcon";

const styles = theme => ({
  ...theme.style.rightBarPopper,
  textIcon: {
    width: "1em"
  },
  iconButton: {
    width: "64px"
  },
  layout: {
    ...theme.style.rightBarPopper.layout
  },
  options: {
    padding: "10px 0px 10px 0px",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "space-around"
  },
  item: {
    width: "6em",
    textAlign: "center",
    padding: "0px 2px 0px 2px"
  },
  control: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  alternateControl: {
    width: "16px",
    height: "100%"
  },
  alternateControlButton: {
    width: "100%",
    fontSize: ".5em",
    padding: "0px"
  },
  label: {},
  badgeRoot: {
    width: "100%",
    display: "inline",
    position: "relative",
    verticalAlign: "middle"
  },
  badgeColor: {
    backgroundColor: theme.colors.disconnectIconColor
  }
});

class MorePopper extends Component {
  state = {
    anchorEl: null
  };

  constructor(props) {
    super(props);
    this.iconRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      anchorEl: this.iconRef.current
    });
  }

  handleClickPopper = event => {
    this.props.setMorePopperExpansion(true);
  };

  handleInvite = () => {
    this.closePopper();
    this.props.showInviteParticipant();
  };

  handleConferenceMute = () => {
    this.props.handleConferenceApiRequest(
      this.props.session.userId,
      "mute_conf"
    );
  };

  handleConferenceUnmute = () => {
    this.props.handleConferenceApiRequest(
      this.props.session.userId,
      "unmute_conf"
    );
  };

  handleConferenceLock = () => {
    this.props.handleConferenceApiRequest(this.props.session.userId, "lock");
  };

  handleConferenceUnlock = () => {
    this.props.handleConferenceApiRequest(this.props.session.userId, "unlock");
  };

  handleWaitingRoom = () => {
    this.props.handleConferenceApiRequest(
      this.props.session.userId,
      "waitingRoom"
    );
  };

  closePopper = () => {
    this.props.setMorePopperExpansion(false);
  };

  getInviteControl = () => {
    const { classes, theme, session } = this.props;
    const disabled = !isHost(session);

    let controlElement = null;

    controlElement = (
      <div className={classes.item}>
        <IconButton
          className={classes.iconButton}
          disabled={disabled}
          onClick={() => this.handleInvite()}
        >
          <SvgIcon iconName="invite" color={disabled ? "inactive" : "active"} />
        </IconButton>
        <div>
          <LocalizedText
            value="invite"
            variant="subtitle2"
            style={{ color: getTextColor(theme, disabled) }}
          />
        </div>
      </div>
    );

    return controlElement;
  };

  getConferenceMuteControl = () => {
    const { conference, classes, session, theme, intl } = this.props;
    const disabled = session.mergedCallState !== "connected";

    let controlElement = null;

    if (isHost(session)) {
      controlElement = (
        <div className={classes.item}>
          {conference.muteState ? (
            <Fragment>
              <IconButton
                className={classes.iconButton}
                disabled={disabled}
                onClick={event => this.handleConferenceUnmute(event)}
                title={
                  intl.formatMessage(getIntl("conferenceMuted")) +
                  TitleNewLine +
                  (disabled
                    ? intl.formatMessage(getIntl("controlDisabled"))
                    : intl.formatMessage(getIntl("clickToUnmuteConference")))
                }
              >
                <SvgIcon
                  iconName="audioMute"
                  color={disabled ? "inactive" : "active"}
                />
              </IconButton>
              <div>
                <LocalizedText
                  value="conferenceMuted"
                  variant="subtitle2"
                  style={{ color: getTextColor(theme, disabled) }}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <IconButton
                className={classes.iconButton}
                disabled={disabled}
                onClick={event => this.handleConferenceMute(event)}
                title={
                  intl.formatMessage(getIntl("conferenceUnmuted")) +
                  TitleNewLine +
                  (disabled
                    ? intl.formatMessage(getIntl("controlDisabled"))
                    : intl.formatMessage(getIntl("clickToMuteConference")))
                }
              >
                <SvgIcon
                  iconName="audioUnmute"
                  color={disabled ? "inactive" : "active"}
                />
              </IconButton>
              <div>
                <LocalizedText
                  value="conferenceUnmuted"
                  variant="subtitle2"
                  style={{ color: getTextColor(theme, disabled) }}
                />
              </div>
            </Fragment>
          )}
        </div>
      );
    }

    return controlElement;
  };

  getConferenceLockControl = () => {
    const { conference, classes, session, theme, intl } = this.props;
    const disabled = session.mergedCallState !== "connected";

    let controlElement = null;

    if (isHost(session)) {
      controlElement = (
        <div className={classes.item}>
          {conference.secure ? (
            <Fragment>
              <IconButton
                className={classes.iconButton}
                disabled={disabled}
                onClick={event => this.handleConferenceUnlock(event)}
                title={
                  intl.formatMessage(getIntl("conferenceLocked")) +
                  TitleNewLine +
                  (disabled
                    ? intl.formatMessage(getIntl("controlDisabled"))
                    : intl.formatMessage(getIntl("clickToUnlockConference")))
                }
              >
                <SvgIcon
                  iconName="conferenceLock"
                  color={disabled ? "inactive" : "active"}
                />
              </IconButton>
              <div>
                <LocalizedText
                  value="conferenceLocked"
                  variant="subtitle2"
                  style={{ color: getTextColor(theme, disabled) }}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <IconButton
                className={classes.iconButton}
                disabled={disabled}
                onClick={event => this.handleConferenceLock(event)}
                title={
                  intl.formatMessage(getIntl("conferenceUnlocked")) +
                  TitleNewLine +
                  (disabled
                    ? intl.formatMessage(getIntl("controlDisabled"))
                    : intl.formatMessage(getIntl("clickToLockConference")))
                }
              >
                <SvgIcon
                  iconName="conferenceUnlock"
                  color={disabled ? "inactive" : "active"}
                />
              </IconButton>
              <div>
                <LocalizedText
                  value="conferenceUnlocked"
                  variant="subtitle2"
                  style={{ color: getTextColor(theme, disabled) }}
                />
              </div>
            </Fragment>
          )}
        </div>
      );
    }

    return controlElement;
  };

  getConferenceRecordControl = () => {
    return <RecordIcon onClick={this.closePopper} />;
  };

  getConferenceQAControl = () => {
    return <QAIcon onClick={this.closePopper} />;
  };

  getConferenceWaitingRoomControl = () => {
    const { classes, theme, session, intl } = this.props;
    const disabled = !window.CtxAppConfigurations.vettingEnabled;
    const vettingConfig = session.vettingConfig;

    let controlElement = null;

    controlElement = (
      <div className={classes.item}>
        <IconButton
          className={classes.iconButton}
          disabled={disabled}
          onClick={() => this.handleWaitingRoom()}
          title={
            intl.formatMessage(getIntl("waitingRoom")) +
            TitleNewLine +
            (disabled
              ? intl.formatMessage(getIntl("controlDisabled"))
              : vettingConfig
              ? intl.formatMessage(getIntl("clickToDisableWaitingRoom"))
              : intl.formatMessage(getIntl("clickToEnableWaitingRoom")))
          }
        >
          {vettingConfig ? (
            <SvgIcon
              iconName="waitingRoomOn"
              color={disabled ? "inactive" : "active"}
            />
          ) : (
            <SvgIcon
              iconName="waitingRoomOff"
              color={disabled ? "inactive" : "active"}
            />
          )}
        </IconButton>
        <div>
          <LocalizedText
            value={vettingConfig ? "waitingRoomOn" : "waitingRoomOff"}
            variant="subtitle2"
            style={{ color: getTextColor(theme, disabled) }}
          />
        </div>
      </div>
    );

    return controlElement;
  };

  getPopperContents = () => {
    const { classes, conference } = this.props;
    return (
      <Fragment>
        <LocalizedText
          value="moreOptions"
          variant="h6"
          className={classes.header}
        />
        <div className={classes.options}>
          {this.getInviteControl()}
          {!window.CtxAppConfigurations.lockAudioModeEnabled &&
            this.getConferenceMuteControl()}
          {this.getConferenceLockControl()}
          {conference.recordEnabled && this.getConferenceRecordControl()}
          {this.getConferenceQAControl()}
          {window.CtxAppConfigurations.vettingEnabled &&
            this.getConferenceWaitingRoomControl()}
        </div>
      </Fragment>
    );
  };

  render() {
    const { classes, intl, qa, popper } = this.props;
    const { anchorEl } = this.state;
    const open = anchorEl != null && popper.more.expandMorePopper;
    const id = open ? "confControlPopper" : null;
    const { unreadDataQAEntries } = qa;

    return (
      <Fragment>
        <IconButton
          id="confControlPopperButton"
          className={classes.popperIconButton}
          disabled={false}
          ref={this.iconRef}
          onClick={this.handleClickPopper}
          title={intl.formatMessage(getIntl("moreOptions"))}
        >
          {unreadDataQAEntries != null && unreadDataQAEntries.size > 0 ? (
            <Badge
              variant="dot"
              color="primary"
              classes={{
                root: classes.badgeRoot,
                colorPrimary: classes.badgeColor
              }}
            >
              <SvgIcon iconName="more" />
            </Badge>
          ) : (
            <SvgIcon iconName="more" />
          )}
        </IconButton>

        <CTXBasePopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          className={classes.layout}
          closePopper={this.closePopper}
        >
          {this.getPopperContents()}
        </CTXBasePopper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  session,
  conference,
  participants,
  qa,
  popper
}) => ({
  session,
  conference,
  participants,
  qa,
  popper
});

const mapDispatchToProps = dispatch => ({
  handleConferenceApiRequest: (userId, apiEndpoint) =>
    dispatch(handleConferenceApiRequest(userId, apiEndpoint)),
  showInviteParticipant: () => dispatch(showInviteParticipant()),
  setMorePopperExpansion: isOpen => dispatch(setMorePopperExpansion(isOpen))
});

export default withTheme(
  withStyles(styles)(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
        MorePopper
      )
    )
  )
);
