import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateMaxDisplayedUsers } from "../../actions";
import LocalizedText from "../reusable/LocalizedText";
import CTXSelect from "../reusable/CTXSelect";
import { InputBase, MenuItem } from "@material-ui/core";
import { getIntl } from "../../utils";
import { useIntl } from "react-intl";

const useMaxDisplayedUsersSettingStyles = makeStyles({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    width: "400px"
  },
  flexRowBox: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "15px",
    alignItems: "center"
  },
  flexItemLarge: {
    flexGrow: 4,
    width: "95%"
  }
});

function MaxDisplayedUsersSetting() {
  const classes = useMaxDisplayedUsersSettingStyles();
  const userId = useSelector(state => state.session.userId);
  const maxDisplayedUsers = useSelector(
    state => state.session.maxDisplayedUsers
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  function handleMaxDisplayedUsersChange(event) {
    const updatedMaxDisplayedUsers =
      event.target.value === "all" ? null : event.target.value;
    dispatch(updateMaxDisplayedUsers(userId, updatedMaxDisplayedUsers));
  }

  return (
    <div className={classes.flexContainer}>
      <div className={classes.flexRowBox}>
        <div className={classes.flexItemLarge}>
          <LocalizedText value="maxDisplayedUsers" variant="subtitle1" />
        </div>
        <div className={classes.flexItemLarge}>
          <CTXSelect
            value={maxDisplayedUsers}
            onChange={handleMaxDisplayedUsersChange}
            input={<InputBase />}
          >
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={"all"}>
              {intl.formatMessage(getIntl("all"))}
            </MenuItem>
          </CTXSelect>
        </div>
      </div>
    </div>
  );
}

const useConferencePreferencesStyles = makeStyles(theme => ({
  header: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "10px"
  },
  headerText: {
    textAlign: "left",
    marginBottom: "10px"
  },
  hrDiv: {
    width: "100%",
    borderTop: `3px solid ${theme.colors.secondaryMainColor}`
  },
  bottomDiv: {
    height: "35px"
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    width: "400px",
    paddingBottom: "10px"
  }
}));

export default function ConferencePreferences() {
  const classes = useConferencePreferencesStyles();

  return (
    <Fragment>
      <div className={classes.header}>
        <LocalizedText
          value="conferencePreferences"
          variant="h6"
          className={classes.headerText}
        />
        <div className={classes.hrDiv} />
      </div>
      <div className={classes.mainContent}>
        <MaxDisplayedUsersSetting />
      </div>
      <div className={classes.bottomDiv} />
    </Fragment>
  );
}
