import { reducer as formReducer } from "redux-form";

import sessionReducer from "../reducers/session";
import websocketReducer from "../reducers/websocket";
import chatsReducer from "../reducers/chats";
import unreadChatsReducer from "../reducers/unreadChats";
import conferenceReducer from "../reducers/conference";
import participantsReducer from "../reducers/participants";
import usersReducer from "../reducers/users";
import userPriorityReducer from "../reducers/userPriority";
import internalDrawerReducer from "./internalDrawer";
import windowReducer from "./window";
import languagesReducer from "./languages";
import themeReducer from "./theme";
import { ChatPopoutWindowReducer } from "../components/Chat";
import { ParticipantListPopoutWindowReducer } from "../components/ParticipantList";
import { InviteParticipantReducer } from "../components/invite";
import { NotificationWindowReducer } from "../components/notification";
import popperReducer from "./popper";
import portalReducer from "./portal";
import { QAReducer } from "../components/QA";
import { QAPopoutWindowReducer } from "../components/QA";
import consumersReducer from "./consumers";
import dataConsumersReducer from "./dataConsumers";
import dataProducersReducer from "./dataProducers";
import meReducer from "./me";
import notificationsReducer from "./notifications";
import peersReducer from "./peers";
import producersReducer from "./producers";
import roomReducer from "./room";
import { VideoPopoutWindowReducer } from "../components/mediasoup/components";

export default function connectNow(state = {}, action) {
  const newState = {
    websocket: websocketReducer(state.websocket, action),
    form: formReducer(state.form, action),
    chats: chatsReducer(state.chats, action),
    conference: conferenceReducer(state.conference, action),
    participants: participantsReducer(state.participants, action),
    users: usersReducer(state.users, action),
    userPriority: userPriorityReducer(state.userPriority, action),
    window: windowReducer(state.window, action),
    languages: languagesReducer(state.languages, action),
    theme: themeReducer(state.theme, action),
    participantListPopoutWindow: ParticipantListPopoutWindowReducer(
      state.participantListPopoutWindow,
      action
    ),
    chatPopoutWindow: ChatPopoutWindowReducer(state.chatPopoutWindow, action),
    inviteParticipant: InviteParticipantReducer(
      state.inviteParticipant,
      action
    ),
    qa: QAReducer(state.qa, action),
    qaPopoutWindow: QAPopoutWindowReducer(state.qaPopoutWindow, action),
    notification: NotificationWindowReducer(state.notification, action),
    popper: popperReducer(state.popper, action),
    portal: portalReducer(state.portal, action),
    consumers: consumersReducer(state.consumers, action),
    dataConsumers: dataConsumersReducer(state.dataConsumers, action),
    dataProducers: dataProducersReducer(state.dataProducers, action),
    me: meReducer(state.me, action),
    notifications: notificationsReducer(state.notifications, action),
    peers: peersReducer(state.peers, action),
    producers: producersReducer(state.producers, action),
    room: roomReducer(state.room, action),
    videoPopoutWindow: VideoPopoutWindowReducer(state.videoPopoutWindow, action)
  };
  newState.session = sessionReducer(
    state.session,
    state.participants,
    newState.participants,
    action
  );
  newState.unreadChats = unreadChatsReducer(
    state.unreadChats,
    state.internalDrawer,
    state.chatPopoutWindow,
    action
  );
  newState.internalDrawer = internalDrawerReducer(
    state.internalDrawer,
    newState.session,
    newState.videoPopoutWindow,
    action
  );

  return newState;
}
