//
// Intercept the REST requests that are normally sent through
// axios and send them to the CM instead.
//

let cmWebRest = {};

cmWebRest.create = function () // mimic the axios create()
{
  return cmWebRest;
};

cmWebRest.get = function (uri) {
  console.log("cmWebRest GET:" + uri);

  if ("/languages/translations/" === uri) {
    // temp workaround for the translation strings, hardcoded below
    return window.get_en(); // HTMCNConfig.js on the CM side
  } else {
    return {};
  }
};

cmWebRest.post = function (uri, requestBody) {
  if (!uri.includes("statistics")) {
    console.log("cmWebRest POST:" + uri);
  }

  if (!window.gCNConnect) {
    console.error("window.gCNConnect is NULL");
  } else {
    return window.gCNConnect.post(uri, requestBody);
  }
};

export default cmWebRest;
