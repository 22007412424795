import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import SvgIcon from "../../Icons/SvgIcon";
import * as cookiesManager from "../cookiesManager";
import * as stateActions from "../redux/stateActions";
import PeerView from "./PeerView";
import {
  setWebCamOn,
  setWebCamOff,
  setChangingWebcam,
  setUpdatingVideoInput,
  setWebcamButtonEnabled
} from "../../../actions";
import { isConnectedToOperator } from "../../../utils";

const styles = theme => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%"
  },
  controls: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  button: {
    pointerEvents: "auto",
    cursor: "pointer",
    "&.on": {
      backgroundColor: theme.colors.sideBarBackgroundColor
    }
  },
  svgIcon: {
    width: "1em"
  }
});

class Me extends React.Component {
  constructor(props) {
    super(props);

    this._mounted = false;
    this._rootNode = null;
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleWebCamOn = () => {
    cookiesManager.setDevices({ webcamEnabled: true });
    this.props.setWebCamOn();
    this.props.setChangingWebcam(true);
  };

  handleWebCamOff = () => {
    cookiesManager.setDevices({ webcamEnabled: false });
    this.props.setWebCamOff();
    this.props.setChangingWebcam(true);
  };

  render() {
    const {
      classes,
      connected,
      me,
      audioProducer,
      videoProducer,
      faceDetection,
      onSetStatsPeerId,
      isDrawer,
      session,
      screenShare
    } = this.props;

    const videoVisible = Boolean(videoProducer) && !videoProducer.paused;
    const { webcamEnabled, fullScreenMode } = session;

    let connectedToOperator = isConnectedToOperator(
      this.props.session.callState
    );

    let webcamButtonEnabled =
      // Enable the button in this case if the webcam is still enabled
      (!connectedToOperator || session.webcamEnabled) &&
      session.webcamButtonEnabled;

    return (
      <div
        data-component="Me"
        ref={node => (this._rootNode = node)}
        className={classes.root}
      >
        <PeerView
          isMe
          isDrawer={isDrawer}
          peer={me}
          audioProducerId={audioProducer ? audioProducer.id : null}
          videoProducerId={videoProducer ? videoProducer.id : null}
          audioRtpParameters={
            audioProducer ? audioProducer.rtpParameters : null
          }
          videoRtpParameters={
            videoProducer ? videoProducer.rtpParameters : null
          }
          audioTrack={audioProducer ? audioProducer.track : null}
          videoTrack={videoProducer ? videoProducer.track : null}
          videoVisible={videoVisible}
          audioCodec={audioProducer ? audioProducer.codec : null}
          videoCodec={videoProducer ? videoProducer.codec : null}
          audioScore={audioProducer ? audioProducer.score : null}
          videoScore={videoProducer ? videoProducer.score : null}
          faceDetection={faceDetection}
          onStatsClick={onSetStatsPeerId}
          screenShare={screenShare}
        />

        {connected && !screenShare && (
          <div className={classes.controls}>
            {!fullScreenMode &&
              (webcamEnabled ? (
                <IconButton
                  className={classes.button}
                  disabled={!webcamButtonEnabled}
                  onClick={this.handleWebCamOff}
                >
                  <SvgIcon
                    iconName="video"
                    className={classes.svgIcon}
                    color={webcamButtonEnabled ? "active" : "inactive"}
                  />
                </IconButton>
              ) : (
                <IconButton
                  className={classes.button}
                  disabled={!webcamButtonEnabled}
                  onClick={this.handleWebCamOn}
                >
                  <SvgIcon
                    iconName="videoMute"
                    className={classes.svgIcon}
                    color={webcamButtonEnabled ? "active" : "inactive"}
                  />
                </IconButton>
              ))}
            {connectedToOperator && (
              <IconButton className={classes.button} disabled={true}>
                <SvgIcon
                  iconName="signalOperator"
                  className={classes.svgIcon}
                  color={"inactive"}
                />
              </IconButton>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ room, me, producers, session }, { screenShare }) => {
  const producersArray = Object.values(producers);
  const audioProducer = producersArray.find(
    producer => producer.track.kind === "audio"
  );
  const videoProducer = producersArray.find(
    producer =>
      producer.track.kind === "video" &&
      producer.screenShare === Boolean(screenShare)
  );

  return {
    connected: room.state === "connected",
    me: me,
    audioProducer: audioProducer,
    videoProducer: videoProducer,
    faceDetection: room.faceDetection,
    session: session
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetStatsPeerId: peerId =>
      dispatch(stateActions.setRoomStatsPeerId(peerId)),
    setWebCamOn: () => dispatch(setWebCamOn()),
    setWebCamOff: () => dispatch(setWebCamOff()),
    setChangingWebcam: value => dispatch(setChangingWebcam(value)),
    setUpdatingVideoInput: value => dispatch(setUpdatingVideoInput(value)),
    setWebcamButtonEnabled: value => dispatch(setWebcamButtonEnabled(value))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Me)
);
