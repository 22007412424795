import React, { Component } from "react";
import LocalizedText from "../reusable/LocalizedText";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { createDataConfIDParty } from "./../../actions";
import { connect } from "react-redux";
import contexWebRest from "../../api/contexWebRest";
import { isHost, getIntl, isCM } from "../../utils";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { injectIntl } from "react-intl";
import Link from "@material-ui/core/Link";

const styles = theme => ({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: "0.875rem", // TODO Create a Simpletext component so that it's wrapped with Typography and gets the fonts automatically.
    lineHeight: "2",
    width: "100%",
    marginBottom: "10px"
  },
  underline: {
    borderBottom: "solid 2px",
    margin: "0 0 0 0",
    color: theme.colors.secondaryTextColor
  },
  instructionTitle: {
    color: alpha(theme.colors.primaryTextColor, 0.7),
    marginTop: "10px"
  },
  instructionContents: {
    marginLeft: "15px"
  }
});
class DialIn extends Component {
  state = {};

  componentDidMount() {
    console.log("dialin mount");
    if (!this.props.session.dataConfID) {
      const { userId } = this.props.session;
      if (this.props.session.confActive) {
        const party = {
          name: this.props.session.username,
          userDefined: this.props.session.userDefined,
          userDefined2: this.props.session.userDefined2,
          userDefined3: this.props.session.userDefined3,
          userDefined4: this.props.session.userDefined4
        };
        this.props.createDataConfIDParty(party, userId);
      } else {
        this.activateConf(userId);
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log("dialin update");
    if (this.props.session.confActive && !prevProps.session.confActive) {
      //conference has been activated while component has been mounted so we can proceed to generate confId
      const party = {
        name: this.props.session.username,
        userDefined: this.props.session.userDefined,
        userDefined2: this.props.session.userDefined2,
        userDefined3: this.props.session.userDefined3,
        userDefined4: this.props.session.userDefined4
      };
      this.props.createDataConfIDParty(party, this.props.session.userId);
    }
  }

  activateConf = userId => {
    const uri = `/conference/activate/${userId}`;
    contexWebRest.post(uri).catch(function (error) {
      console.log(error);
    });
  };

  getCMDialInInfo() {
    const { classes, session, intl, conference } = this.props;
    const phoneNumber = intl.formatMessage(getIntl("phoneNumber"));
    const conferenceID = conference.confId;
    const partyPIN = session.pin;
    const conferencePassword = conference.confPassword;
    const ENCRYPTED_PIN = "###";

    let attdID = session.dataConfID;
    if (attdID == null) {
      attdID = "";
    }

    return (
      <Grid item xs zeroMinWidth className={classes.root}>
        <LocalizedText value="dialIn" variant="h6" />
        <div className={classes.underline}></div>
        <div>
          <LocalizedText
            className={classes.instructionTitle}
            value="dialInStep1Call"
          />
          <Link
            className={classes.instructionContents}
            href={"tel:" + phoneNumber}
            color="inherit"
            variant="body2"
            underline="always"
            target="_blank"
            rel="noreferrer"
          >
            {phoneNumber}
          </Link>
          <LocalizedText
            className={classes.instructionTitle}
            value="dialInStep2ConfID"
          />
          <span className={classes.instructionContents}>{conferenceID}#</span>
          {attdID.toString().substring(0, 1) === "H" ? (
            //It's Host
            <LocalizedText
              className={classes.instructionTitle}
              value="dialInStep3PIN"
            />
          ) : (
            //It's Participant
            <>
              {conferencePassword ? ( //ConferencePassword defined
                <>
                  <LocalizedText
                    className={classes.instructionTitle}
                    value="dialInStep3Password"
                  />
                  <span className={classes.instructionContents}>
                    {conferencePassword}#
                  </span>
                  {partyPIN ? (
                    //conference password and pin defined
                    //Display pin only if it's not encrypted
                    <>
                      <LocalizedText
                        className={classes.instructionTitle}
                        value="dialInStep4PIN"
                      />
                      {partyPIN !== ENCRYPTED_PIN && (
                        <span className={classes.instructionContents}>
                          {partyPIN}
                        </span>
                      )}
                    </>
                  ) : (
                    //conference password defined but not pin
                    <>
                      <LocalizedText
                        className={classes.instructionTitle}
                        value="dialInStep4AttendeeID"
                      />
                      <span className={classes.instructionContents}>
                        {attdID}#
                      </span>
                    </>
                  )}
                </>
              ) : (
                //ConferencePassword not defined
                <>
                  {partyPIN ? (
                    //Conference Password undef, pin defined
                    //Display pin only if it's not encrypted
                    <>
                      <LocalizedText
                        className={classes.instructionTitle}
                        value="dialInStep3PIN"
                      />
                      {partyPIN !== ENCRYPTED_PIN && (
                        <span className={classes.instructionContents}>
                          {partyPIN}
                        </span>
                      )}
                    </>
                  ) : (
                    //conference password and pin undef
                    <>
                      <LocalizedText
                        className={classes.instructionTitle}
                        value="dialInStep3AttendeeID"
                      />
                      <span className={classes.instructionContents}>
                        {attdID}#
                      </span>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Grid>
    );
  }

  render() {
    if (isCM()) return this.getCMDialInInfo();

    const { classes, session, intl } = this.props;
    let phoneNumber = intl.formatMessage(getIntl("phoneNumber"));
    let passcode = isHost(session)
      ? this.props.conference.hostCode
      : this.props.conference.guestCode;
    if (passcode == null) {
      passcode = "";
    }
    let dataConfID = this.props.session.dataConfID;
    if (dataConfID == null) {
      dataConfID = "";
    }

    let startDataConfID = this.props.languages.translations.dataConfIdDetailed;

    return (
      <Grid item xs zeroMinWidth className={classes.root}>
        <LocalizedText value="dialIn" variant="h6" />
        <div className={classes.underline}></div>
        <div>
          <LocalizedText
            className={classes.instructionTitle}
            value="dialInStep1Call"
          />
          <Link
            className={classes.instructionContents}
            href={"tel:" + phoneNumber}
            color="inherit"
            variant="body2"
            underline="always"
            target="_blank"
            rel="noreferrer"
          >
            {phoneNumber}
          </Link>
          <LocalizedText
            className={classes.instructionTitle}
            value="dialInStep2Passcode"
          />
          <span className={classes.instructionContents}>{passcode}#</span>
          {session.pin ? (
            <>
              <LocalizedText
                className={classes.instructionTitle}
                value="dialInStep3PIN"
              />
              <span className={classes.instructionContents}>
                {session.pin}#
              </span>
              <LocalizedText
                className={classes.instructionTitle}
                value="dialInStep4ParticipantID"
              />
            </>
          ) : (
            <LocalizedText
              className={classes.instructionTitle}
              value="dialInStep3ParticipantID"
            />
          )}
          <span className={classes.instructionContents}>
            {startDataConfID + "  "}
            {dataConfID}#
          </span>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = ({ session, conference, languages }) => ({
  session,
  conference,
  languages
});

const mapDispatchToProps = dispatch => ({
  createDataConfIDParty: (party, userId) =>
    dispatch(createDataConfIDParty(party, userId))
});

export default withStyles(styles)(
  injectIntl(connect(mapStateToProps, mapDispatchToProps)(DialIn))
);
