import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { hideInternalDrawer } from "../../actions";
import { IconButton } from "@material-ui/core";
import { isMobileOrTablet, getIntl } from "../../utils";
import SvgIcon from "../Icons/SvgIcon";
import Paper from "@material-ui/core/Paper";
import ConferencePreferences from "../menus/ConferencePreferences";
import { useIntl } from "react-intl";

const headerHeight = "32px";

const useDrawerHeaderStyles = makeStyles({
  drawerHeader: {
    width: "100%",
    textAlign: "right",
    height: headerHeight,
    padding: "3px 3px 3px 0px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "8px 8px 8px 8px"
  },
  closeHeaderIcon: {
    width: "0.7em"
  }
});

function DrawerHeader() {
  const classes = useDrawerHeaderStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <div className={classes.drawerHeader}>
      <IconButton
        className={classes.closeHeaderButton}
        onClick={() => dispatch(hideInternalDrawer())}
        title={intl.formatMessage(getIntl("close"))}
      >
        <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
      </IconButton>
    </div>
  );
}

const componentMinWidthPx = 375;

const useConferencePreferencesDrawerStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    minWidth: componentMinWidthPx,
    maxWidth: componentMinWidthPx
  },
  paper: {
    backgroundColor: theme.colors.popoverBackgroundColor,
    color: theme.colors.primaryTextColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "0px",
    height: "100%",
    width: "100%"
  },
  container: {
    display: "flex",
    flex: "1 1",
    minHeight: "0px",
    height: "0px",
    flexDirection: "column",
    width: "100%",
    padding: "8px 20px 0px 20px"
  }
}));

export default function ConferencePreferencesDrawer() {
  const classes = useConferencePreferencesDrawerStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {!isMobileOrTablet() && <DrawerHeader />}
        <div className={classes.container}>
          <ConferencePreferences />
        </div>
      </Paper>
    </div>
  );
}
