import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { hideConferencePreferencesMenu } from "../../actions";
import CTXBaseWindow from "../reusable/CTXBaseWindow";
import ConferencePreferences from "../menus/ConferencePreferences";

const useStyles = makeStyles({
  container: {
    display: "flex",
    zIndex: 11,
    width: "auto",
    height: "auto",
    alignSelf: "center",
    position: "absolute"
  }
});

export default function ConferencePreferencesMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div className={classes.container}>
      <CTXBaseWindow
        component={<ConferencePreferences />}
        overrideHandleClose={() => dispatch(hideConferencePreferencesMenu())}
        disableClickAwayClose={true}
        disableContainerStyle
      />
    </div>
  );
}
