import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import TextButton from "./TextButton";
import LocalizedText from "./LocalizedText";
import { withTheme } from "@material-ui/core/styles";
import SvgIcon from "../Icons/SvgIcon";
import classNames from "classnames";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  layout: {
    backgroundColor: theme.colors.popoverBackgroundColor,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "300px",
    color: theme.colors.primaryTextColor,
    borderRadius: "10px",
    border: `1px solid ${theme.colors.primaryMainColor}`
  },
  closeHeader: {
    width: "100%",
    textAlign: "right",
    height: "34px",
    padding: "3px 3px 3px 0px"
  },
  closeHeaderButton: {
    fontSize: "1.2em",
    padding: "14px 14px 8px 8px"
  },
  closeHeaderIcon: {
    width: "0.7em"
  },
  header: {
    width: "100%",
    textAlign: "left",
    padding: "0px 0px 0px 37px"
  },
  content: {
    width: "100%",
    padding: "10px 14px 10px 14px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "space-around",
    justifyContent: "center"
  },
  typography: {
    color: theme.colors.primaryTextColor
  },
  dataDisplay: {
    width: "100%",
    textAlign: "center",
    overflowWrap: "break-word",
    paddingBottom: "10px"
  },
  actionButton: {
    fontSize: "1.5em",
    width: "64px"
  },
  ejectLabel: {
    color: theme.colors.primaryTextColor
  },
  noSelect: {
    userSelect: "none"
  },
  ejectCheckbox: {
    color: theme.colors.primaryMainColor,
    paddingLeft: "14px"
  },
  checkboxOutlineBlankIcon: {
    width: "18px",
    height: "18px"
  },
  checkboxIcon: {
    backgroundColor: theme.colors.activeIconColor,
    borderRadius: "4px",
    width: "18px",
    height: "18px"
  },
  errorTextColor: {
    color: theme.colors.errorTextColor
  },
  primaryTextColor: {
    color: theme.colors.primaryTextColor
  }
});

class CTXIconButtonConfirmModal extends Component {
  state = {
    open: false,
    eject: window.CtxAppConfigurations.ejectFromWebSession !== 2
  };

  handleOpen = event => {
    event && event.stopPropagation(); //don't click the containing div
    this.setState({ open: true });
  };

  handleClose = (event, callback) => {
    event && event.stopPropagation(); //don't click the containing div
    this.setState({ open: false });
    callback && callback(event, { eject: this.state.eject });
  };

  handleMuiClose = (event, reason) => {
    event && event.stopPropagation(); //don't click the containing div
    this.setState({ open: false });
    this.handleClose(event);
  };

  handleModalClick = event => {
    event && event.stopPropagation(); //don't click the containing div
  };

  handleConfirm = event => {
    event && event.stopPropagation(); //don't click the containing div
    this.handleClose(event, this.props.onConfirm);
  };

  getDataComponent = () => {
    const { data, classes } = this.props;
    if (data) {
      return (
        <Typography
          className={classes.dataDisplay}
          variant="subtitle2"
          color="inherit"
        >
          <span>{data.name}</span>
        </Typography>
      );
    } else {
      return null;
    }
  };

  handleCheckBoxChange = eventObj => {
    this.setState({
      eject: eventObj.target.checked
    });
  };

  removeWebUserComponent = () => {
    const { removeWebUser, classes } = this.props;
    if (removeWebUser) {
      return (
        <FormControlLabel
          classes={{
            label: classNames(classes.ejectLabel, classes.noSelect)
          }}
          control={
            <Checkbox
              className={classes.ejectCheckbox}
              checked={this.state.eject}
              onChange={eventObj => this.handleCheckBoxChange(eventObj)}
              color="primary"
              icon={
                <CheckBoxOutlineBlankIcon
                  viewBox="3 3 18 18"
                  className={classes.checkboxOutlineBlankIcon}
                />
              }
              checkedIcon={
                <CheckBoxIcon
                  viewBox="3 3 18 18"
                  className={classes.checkboxIcon}
                />
              }
            />
          }
          label={
            <LocalizedText value="ejectFromWebSession" variant="subtitle2" />
          }
        />
      );
    }
  };

  render() {
    // console.log("renderingModal", this.state.open, this);
    const {
      classes,
      disabled,
      name,
      label,
      iconClass,
      svgName,
      svgColor,
      svgClass,
      title
    } = this.props;

    return (
      <Fragment>
        <IconButton
          className={iconClass}
          disabled={disabled}
          onClick={event => this.handleOpen(event)}
          title={title}
          style={disabled ? { pointerEvents: "auto" } : undefined}
        >
          <SvgIcon iconName={svgName} color={svgColor} className={svgClass} />
        </IconButton>

        <Modal
          open={this.state.open}
          onClose={this.handleMuiClose}
          className={classes.container}
          BackdropProps={{
            invisible: true
          }}
          disablePortal
          disableScrollLock
        >
          <div
            className={classes.layout}
            onClick={event => this.handleModalClick(event)}
          >
            <div className={classes.closeHeader}>
              <IconButton
                className={classes.closeHeaderButton}
                disabled={false}
                onClick={event => this.handleClose(event)}
              >
                <SvgIcon iconName="close" className={classes.closeHeaderIcon} />
              </IconButton>
            </div>
            <LocalizedText
              value={label}
              variant="h6"
              className={classes.header}
            />
            <div className={classes.content}>
              {this.getDataComponent()}
              {this.removeWebUserComponent()}
              <TextButton
                className={`${name}ConfirmButton`}
                onClick={event => this.handleConfirm(event)}
                color="error"
              >
                <LocalizedText value="yes" className={classes.errorTextColor} />
              </TextButton>
              <TextButton
                className={`${name}CancelButton`}
                onClick={event => this.handleClose(event)}
                color="secondary"
              >
                <LocalizedText
                  value="no"
                  className={classes.primaryTextColor}
                />
              </TextButton>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default withTheme(withStyles(styles)(CTXIconButtonConfirmModal));
